/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	margin: 0;
	font-family: 'Poppins', sans-serif;
}

/*scrollbar*/
* {
	scrollbar-width: thin;
	scrollbar-color: rgba(16, 185, 129);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
	width: 12px;
}

*::-webkit-scrollbar-track {
	background: rgba(31, 41, 55);
}

*::-webkit-scrollbar-thumb {
	background-color: rgba(31, 41, 55);
	border: 2px solid rgba(16, 185, 129);
}
